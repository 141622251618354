import http from "@/utils/http";

export async function pay_invoice(data){
    const req = (await http.post(`/payments/payment-intent`,data));
    return req.data; 
}

export async function get_payments_list(type, data){
    const req = (await http.get(`/payments/${type}/filter`,{params:data}));
    return req.data;
}

export async function get_payment_receipt(data){
    const req = (await http.get(`/payments/receipt`, {params:data}));
    return req.data;
}

export async function connect_bank_account(){
    const req = (await http.post('/payments/connect-account'));
    return req.data;
}

export async function get_connected_account(){
    const req = (await http.get('/payments/connect-account'));
    return req.data;
}
